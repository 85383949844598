export default async function fetchRequest(url, method, body, accessToken) {
  try {
    const resp = await fetch(url, {
      method,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    });

    return resp.json();
  } catch (error) {
    return error;
  }
}
