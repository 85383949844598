import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Fab, Grid, TextField } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { SocketContext } from "../../context/socket";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    height: "550px",
    overflowY: "auto",
  },
  inline: {
    display: "inline",
  },
  sendIcon: {
    width: "40px",
    height: "40px",
    cursor: "pointer",
  },
}));

export default function ChatMessages({
  messages,
  userData,
  accessToken,
  channel,
}) {
  const socket = useContext(SocketContext);
  const messagesEndRef = useRef(null);
  const textFieldRef = useRef(null);

  const [message, setMessage] = useState("");

  useEffect(() => {
    scrollToBottomMessage();
  }, [messages]);

  const scrollToBottomMessage = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const handleSendMessage = () => {
    if (message !== "") {
      // if message is not empty, emit message and userdata to
      // the web socket server.
      // also pass accessToken so the WSS can save message on main api server
      const dummyUser = { name: "John Snow", profileImageUrl: "" };

      socket.emit("sent-chat-message", {
        channel: channel,
        userData: userData || dummyUser,
        accessToken: accessToken,
        message,
        dateSent: new Date(), // using this so we can save on the main api
      });

      setMessage("");
      console.log("meeting chat emit sent");
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <small>
        Messages sent can only be seen by people present in the meeting.
      </small>
      <List>
        {messages.map((singleEntry, i) => (
          <React.Fragment key={i}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  alt={singleEntry.name}
                  src={singleEntry.profileImageUrl}
                />
              </ListItemAvatar>
              <ListItemText
                primary={singleEntry.name}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {singleEntry.message} <br></br>
                      <span style={{ fontSize: "11px" }}>
                        {singleEntry.time}
                      </span>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
      <Grid
        container
        style={{
          padding: "20px",
          background: "white",
        }}
      >
        <Grid item xs={11}>
          <TextField
            id="outlined-basic-email"
            label="Type Something"
            fullWidth
            multiline
            maxRows={5}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            ref={textFieldRef}
          />
        </Grid>
        <Grid xs={1} align="right">
          <Fab
            color="primary"
            aria-label="add"
            className={classes.sendIcon}
            onClick={handleSendMessage}
          >
            <Send />
          </Fab>
        </Grid>
      </Grid>
      <div ref={messagesEndRef} />
    </div>
  );
}
