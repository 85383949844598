import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import UserVideo from "../UserVideo";
import cssModule from "./Application.module.css";
import { SocketContext } from "../../context/socket";
import fetchRequest from "../../utils/request";
import UsersMessagesTabs from "../UsersMessagesTabs";
import { AppBar, Toolbar } from "@material-ui/core";
import { CallEnd } from "@material-ui/icons";
import LeftMeeting from "../LeftMeeting";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/** CONFIG **/
const USE_AUDIO = true;
const USE_VIDEO = true;
const ICE_SERVERS = [
  { url: "stun:stun.l.google.com:19302" },
  {
    url: "turn:turn.ifyskitchen.com:3478",
    username: "ifyskitchen",
    credential: "1f75k176h3n",
  },
];

const Application = () => {
  const socket = useContext(SocketContext);
  const { webinarId } = useParams();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasLeft, setHasLeft] = useState(false);

  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [myLocalStream, setMyLocalStream] = useState(null);
  const [peerMediaList, setPeerMediaList] = useState([]); // holds content for each video cards
  const [liveMessages, setLiveMessages] = useState([]);
  const [liveUsers, setLiveUsers] = useState([]);

  //let socket = null; /* our socket.io connection to our webserver */
  let local_media_stream = myLocalStream; /* our own microphone / webcam */
  let peers = {}; /* keep track of our peer connections, indexed by peer_id (aka socket.io id) */
  let peer_media_elements = {}; /* keep track of our <video>/<audio> tags, indexed by peer_id */

  const onLoginSubmit = async (e) => {
    e.preventDefault();
    const data = { email, password };
    const URL = process.env.REACT_APP_API_URL;

    try {
      const loginResponse = await fetchRequest(
        `${URL}/auth/login`,
        "POST",
        data
      );

      if (loginResponse.status === "success") {
        setAccessToken(loginResponse.data.accessToken);
        setUserData(loginResponse.data.user);

        const authResponse = await fetchRequest(
          `${URL}/webinar/authenticate`,
          "POST",
          { webinarId },
          loginResponse.data.accessToken
        );

        if (authResponse.status === "success") {
          setIsLoggedIn(true);
          setAccessToken(loginResponse.data.accessToken);
          setUserData(loginResponse.data.user);

          const userdata = {
            accessToken: loginResponse.data.accessToken,
            data: loginResponse.data.user,
          };

          startLiveStream(webinarId, userdata);
          return;
        } else {
          alert(authResponse.message);
        }
      } else {
        alert(loginResponse.message);
        return;
      }
    } catch (error) {
      alert(error.message);
      return;
    }
  };

  //Function to leave channel connection
  const part_chat_channel = (channel) => {
    socket.emit("part", channel);
  };

  const handleLeaveMeeting = () => {
    part_chat_channel(webinarId);
    // socket.disconnect();

    setHasLeft(true);
    if (local_media_stream) {
      // stop using webcam
      local_media_stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  };

  const startLiveStream = (webinarChannel, userdata) => {
    // Socket.IO automatically tries to reconnect when a client
    // is disconnected from the signaling server (probably bad internet)
    // This listens for reconnections subsequently when after the
    // client disconnect the first time. so we use this to reconnect
    // the client and let them rejoin the webinar.
    socket.on("connect", function () {
      console.log("Connected!");

      // remove all media components when we reconnect so it gets
      // updated with current data on the network
      setPeerMediaList([]);
      join_chat_channel(webinarChannel, userdata);
    });

    // This initializes the user's audio and video and
    // joins the user to the channel/webinar.
    setupLocalMedia(() => {
      /* once the user has given us access to their
       * microphone/camcorder, join the channel and start peering up */
      console.log("so we join chat");
      join_chat_channel(webinarChannel, userdata);
    });

    //Disconnection from Socket
    socket.on("disconnect", () => {
      console.log("Disconnected from signaling server");
      /* Tear down all of our peer connections when we disconnect */
      for (const peer_id in peers) {
        peers[peer_id].close();
      }

      peers = {};
      peer_media_elements = {};
    });

    //Function to join channel connection
    const join_chat_channel = (channel, userdata) => {
      socket.emit("join", { channel: channel, userdata: userdata });
    };

    /** 
    * When we join a group, our signaling server will send out 'addPeer' events to each pair
    * of users in the group 
     (creating a fully-connected graph of users, ie if there are 6 people in the channel you will connect directly to the other 5,
      so there will be a total of 15 connections in the network, using the formula for graph (n(n-1))/2). 
    */
    socket.on("addPeer", (config) => {
      console.log("Signaling server said to add peer:", config);

      let peer_id = config.peer_id;
      let channelUsers = config.channelUsers;
      const user = config.userdata.data;

      if (peer_id in peers) {
        /* This could happen if the user joins multiple channels where the other peer is also in. */
        console.log("Already connected to peer ", peer_id);
        return;
      }

      // Update Live Users
      setLiveUsers(channelUsers);

      let peer_connection = new RTCPeerConnection(
        { iceServers: ICE_SERVERS },
        { optional: [{ DtlsSrtpKeyAgreement: true }] }
      );

      peers[peer_id] = peer_connection;

      peer_connection.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit("relayICECandidate", {
            peer_id: peer_id,
            ice_candidate: {
              sdpMLineIndex: event.candidate.sdpMLineIndex,
              candidate: event.candidate.candidate,
            },
          });
        }
      };

      peer_connection.onaddstream = (event) => {
        const newStream = {
          peer_id: peer_id,
          stream: event.stream,
          userInfo: user, //weird uh? lol
        };

        peer_media_elements[peer_id] = newStream;

        setPeerMediaList((prevState) => {
          // we want lead chef shown first. so should be at the start of array
          if (newStream.userInfo.role) {
            let role = newStream.userInfo.role;
            if (role === "admin" || role === "lead_chef") {
              return [newStream, ...prevState];
            }
          }

          //otherwise append new user
          return [...prevState, newStream];
        });
      };

      /* Add our local stream */
      peer_connection.addStream(local_media_stream);

      /* Only one side of the peer connection should create the
       * offer, the signaling server picks one to be the offerer.
       * The other user will get a 'sessionDescription' event and will
       * create an offer, then send back an answer 'sessionDescription' to us
       */
      if (config.should_create_offer) {
        console.log("Creating RTC offer to ", peer_id);
        peer_connection.createOffer(
          function (local_description) {
            console.log("Local offer description is: ", local_description);
            peer_connection.setLocalDescription(
              local_description,
              function () {
                socket.emit("relaySessionDescription", {
                  peer_id: peer_id,
                  session_description: local_description,
                });
                console.log("Offer setLocalDescription succeeded");
              },
              function () {
                alert("Offer setLocalDescription failed!");
              }
            );
          },
          function (error) {
            console.log("Error sending offer: ", error);
          }
        );
      }
    });

    /**
     * Peers exchange session descriptions which contains information
     * about their audio / video settings and that sort of stuff. First
     * the 'offerer' sends a description to the 'answerer' (with type
     * "offer"), then the answerer sends one back (with type "answer").
     */
    socket.on("sessionDescription", function (config) {
      console.log("Remote description received: ", config);
      let peer_id = config.peer_id;
      let peer = peers[peer_id];
      let remote_description = config.session_description;
      console.log(config.session_description);

      let desc = new RTCSessionDescription(remote_description);
      peer.setRemoteDescription(
        desc,
        function () {
          console.log("setRemoteDescription succeeded");
          if (remote_description.type === "offer") {
            console.log("Creating answer");
            peer.createAnswer(
              function (local_description) {
                console.log("Answer description is: ", local_description);
                peer.setLocalDescription(
                  local_description,
                  function () {
                    socket.emit("relaySessionDescription", {
                      peer_id: peer_id,
                      session_description: local_description,
                    });
                    console.log("Answer setLocalDescription succeeded");
                  },
                  function () {
                    // alert("Answer setLocalDescription failed!");
                  }
                );
              },
              function (error) {
                console.log("Error creating answer: ", error);
                console.log(peer);
              }
            );
          }
        },
        function (error) {
          console.log("setRemoteDescription error: ", error);
        }
      );
      console.log("Description Object: ", desc);
    });

    /**
     * The offerer will send a number of ICE Candidate blobs to the answerer so they
     * can begin trying to find the best path to one another on the net.
     */
    socket.on("iceCandidate", function (config) {
      let peer = peers[config.peer_id];
      let ice_candidate = config.ice_candidate;
      peer.addIceCandidate(new RTCIceCandidate(ice_candidate));
    });

    /**
     * When a user leaves a channel (or is disconnected from the
     * signaling server) everyone will recieve a 'removePeer' message
     * telling them to trash the media channels they have open for those
     * that peer. If it was this client that left a channel, they'll also
     * receive the removePeers. If this client was disconnected, they
     * wont receive removePeers, but rather the
     * socket.on('disconnect') code will kick in and tear down
     * all the peer sessions.
     */
    socket.on("removePeer", function (config) {
      console.log("Signaling server said to remove peer:", config);
      let peer_id = config.peer_id;
      let channelUsers = config.channelUsers;

      // Update Live Users
      setLiveUsers(channelUsers);

      // remove User Video
      setPeerMediaList((peerMediaList) =>
        peerMediaList.filter((obj) => obj.peer_id !== peer_id)
      );

      if (peer_id in peers) {
        peers[peer_id].close();
      }

      delete peers[peer_id];
      delete peer_media_elements[config.peer_id];
    });

    // Live Messages Listening
    socket.on("new-message", (payload) => {
      console.log(payload);
      const entry = {
        name: payload.userData.name,
        profileImageUrl: payload.userData.profileImageUrl,
        message: payload.message,
        time: new Date(payload.dateSent).toLocaleTimeString().toLowerCase(),
      };

      setLiveMessages((prevState) => [...prevState, entry]);
    });
  };

  /** User Local video and audio configuration **/
  const setupLocalMedia = async (callback, errorback) => {
    if (local_media_stream != null) {
      /* ie, if we've already been initialized */
      if (callback) callback();
      return;
    }

    /* Ask user for permission to use the computers microphone and/or camera,
     * attach it to an <audio> or <video> tag if they give us access. */
    console.log("Requesting access to local audio / video inputs");

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: USE_AUDIO,
        video: USE_VIDEO,
      });

      console.log("Access granted to audio/video");
      local_media_stream = stream;

      setMyLocalStream(stream);
      if (callback) callback();
    } catch (error) {
      console.log("Access denied for audio/video");
      alert(
        "You chose not to provide access to the camera/microphone, demo will not work."
      );
      console.log(error);
    }

    if (errorback) errorback();
  };

  const classes = useStyles();
  return (
    <div>
      {isLoggedIn ? (
        hasLeft ? (
          <LeftMeeting />
        ) : (
          <div className={cssModule.application_wrapper}>
            <h4>LIVE MEETING. Meeting ID: {webinarId} </h4>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={3} md={3}>
                <div className={cssModule.box}>
                  <AppBar position="static">
                    <Toolbar>
                      <Typography variant="h6">My Local Stream</Typography>
                    </Toolbar>
                  </AppBar>
                  <UserVideo
                    type="local-stream"
                    stream={myLocalStream}
                    userData={userData}
                  />
                  <br></br>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CallEnd />}
                    onClick={handleLeaveMeeting}
                  >
                    Leave
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div style={{ border: "1px solid blue" }}>
                  <AppBar position="static">
                    <Toolbar>
                      <Typography variant="h6">Others In Meeting</Typography>
                    </Toolbar>
                  </AppBar>
                  <div className={cssModule.box}>
                    <Grid container spacing={2}>
                      {peerMediaList.map((media, i) => (
                        <Grid item xs={12} md={6} sm={6} key={i}>
                          <div style={{ height: "370px", width: "100%" }}>
                            <UserVideo
                              stream={media.stream}
                              userData={media.userInfo}
                            />
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <div>
                  <UsersMessagesTabs
                    liveUsers={liveUsers}
                    liveMessages={liveMessages}
                    userData={userData}
                    accessToken={accessToken}
                    channel={webinarId}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: "center" }}
            >
              Login to IfysKitchen To Join Live Stream
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onLoginSubmit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <a
                    href="https://staging.ifyskitchen.com/sign-up"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"Don't have an account? Sign Up"}
                  </a>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </div>
  );
};

export default Application;
