import React from "react";

const LeftMeeting = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h2>You Left!</h2>
      <h2>Thanks for testing this out!</h2>
      <p> Hope The Live Meeting Went Well!</p>
      <br></br>
      <p>Okay, Goodbye. And dont come late next time </p>
    </div>
  );
};

export default LeftMeeting;
