import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h3>
        Welcome. This is Basically A live Demo of IfysKitchen Video Sessions
      </h3>
      <p>
        {" "}
        You will be required to Login using your IfysKitchen details to verify
        your subscription status
      </p>
      <br></br>
      <br></br>
      <p>URL of a livestream looks something like: </p>
      <p>{process.env.REACT_APP_HOST_URL}/w-01594-41081-111139</p>
      <p>
        <Link to="/w-01594-41081-111139">
          Join this already created Live stream (for testing){" "}
        </Link>
      </p>
    </div>
  );
};

export default Home;
