import React, { useRef, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { red } from "@material-ui/core/colors";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import ShareIcon from "@material-ui/icons/Share";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MicIcon from "@material-ui/icons/Mic";
import {
  MicOff,
  PresentToAll,
  Videocam,
  VideocamOff,
} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // height: "100%",
    },

    media: {
      border: "1px solid grey",
      width: "100%",
      height: "250px",
    },
    avatar: {
      backgroundColor: red[500],
    },
  })
);

const rolesMap = {
  user: "User",
  lead_chef: "Lead Chef",
  admin: "Admin",
};

export default function UserVideo({ type, stream, userData }) {
  const [audioOn, setAudioOn] = useState(false);
  const [videoOn, setVideoOn] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    (() => {
      if (stream !== videoRef.current.srcObject) {
        videoRef.current.srcObject = stream;

        if (type === "local-stream") {
          // Disable Audio and Video By Default
          stream.getAudioTracks().forEach((track) => (track.enabled = false));
          stream.getVideoTracks().forEach((track) => (track.enabled = false));
        }
      }
    })();
  }, [stream, type]);

  const handleSetAudio = () => {
    const audioState = audioOn;
    setAudioOn(!audioOn);
    stream.getAudioTracks().forEach((track) => (track.enabled = !audioState));
  };

  const handleSetVideo = () => {
    const videoState = videoOn;
    setVideoOn(!videoOn);
    stream.getVideoTracks().forEach((track) => (track.enabled = !videoState));
  };

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            J
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={userData ? userData.name : "Loading info..."}
        subheader={
          userData && userData.role
            ? rolesMap[userData.role]
            : "Loading info..."
        }
      />

      <video
        className={classes.media}
        controls
        autoPlay="autoplay"
        muted={type === "local-stream"}
        ref={videoRef}
      ></video>

      {type === "local-stream" && (
        <CardActions disableSpacing>
          <Tooltip title="Microphone">
            <IconButton aria-label="Microphone" onClick={handleSetAudio}>
              {audioOn ? <MicIcon /> : <MicOff />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Video">
            <IconButton aria-label="Video" onClick={handleSetVideo}>
              {videoOn ? <Videocam /> : <VideocamOff />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Share Screen">
            <IconButton
              aria-label="Video"
              onClick={() => alert("Not supported!")}
            >
              <PresentToAll />
            </IconButton>
          </Tooltip>
        </CardActions>
      )}
    </Card>
  );
}
